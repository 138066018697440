/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import Header from './header';
import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider } from 'theme-ui';
import theme from '../theme';

import '../assets/fonts/NeutraText-Bold.ttf';
import '../assets/fonts/NeutraText-Book.ttf';
import '../assets/fonts/NeutraText-Demi.ttf';
import '../assets/fonts/NeutraText-Light.ttf';

const Content = styled.div`
  padding: 1rem;
  //min-height: 100vh;
  box-sizing: border-box;
  overflow: auto;

  .slideshow-container {
    margin-bottom: 2rem;

    .arrows {
      //.btn-arrow-left {
      //  left: 15%;
      //}
      //
      //.btn-arrow-right {
      //  right: 15%;
      //}
    }

    .arrow {
      &::before,
      &::after {
        background-color: #2f3539;
      }
    }

    .show-index {
      color: #2f3539;
      font-family: 'Neutraface Text', sans-serif;
      bottom: -2.6rem;

      .dot {
        background-color: #59666e;

        &.is-active {
          background-color: #2f3539;
        }
      }
    }
  }

  .slides {
    overflow: auto;
    width: 100%;
  }

  .slide {
    max-width: 800px;
    overflow: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Global
          styles={css`
            body {
              margin: 0;
              padding: 0;
              //width: 100%;
              font-family: 'Arboria', 'Avenir', 'Helvetica', 'sans-serif';
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          `}
        />
        <Content>
          <Header />
          <SimpleReactLightbox>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
          </SimpleReactLightbox>
        </Content>
      </>
    )}
  />
);

export default Layout;
