import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Text, Image } from 'theme-ui';

import Logo from '../assets/logo.svg';
// import Layout from './layout';

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SiteHeader = styled.header`
  background: transparent;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  a {
    text-decoration: none;
    color: #59666e;
    font-weight: normal;
    //font-family: 'Neutra Text', sans-serif;
    text-transform: uppercase;
    //transition: text-decoration 0.1s ease-in;

    &:hover {
      //text-decoration: underline;
    }
  }

  .nav-link {
    margin-left: 1.6rem;
    color: #59666e;
  }
`;

const Header = () => {
  return (
    <SiteHeader>
      <Content>
        <Link to="/">
          <Image src={Logo} alt="logo" sx={{ width: ['100px', '200px'] }} />
        </Link>
        <NavLinksContainer>
          <Link to="/">
            <Text variant="nav" className="nav-link">
              Home
            </Text>
          </Link>
          <Link to="/about">
            <Text className="nav-link">About Us</Text>
          </Link>
          <Link to="/validation-studies">
            <Text className="nav-link">Validation Studies</Text>
          </Link>
          <Link to="/background-science">
            <Text className="nav-link">Background Science</Text>
          </Link>
          <Link to="/gallery">
            <Text className="nav-link">Gallery</Text>
          </Link>
          <Link to="/contact">
            <Text className="nav-link">Contact Us</Text>
          </Link>
        </NavLinksContainer>
      </Content>
    </SiteHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
