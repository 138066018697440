export default {
  breakpoints: ['36em', '50em', '64em', '80em', '96rem'],
  fontSizes: [
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    64,
    72,
    84,
    96,
    108,
    124,
    136,
    148,
    160,
  ],
  colors: {
    background: '#e6e9eb',
    // blue: '#07c',
    // lightgray: '#f6f6ff',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'Neutra Text, sans-serif',
    heading: 'Arboria, sans-serif',
    subheading: 'Neutra Text, sans-serif',
  },
  fontWeights: {
    body: 400,
    lighter: 'lighter',
    bold: 'bold',
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {},
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'lighter',
      marginLeft: [null, null, -2, null],
      fontSize: [7, 10, 11, 13],
    },
    subheading: {
      fontFamily: 'subheading',
      fontWeight: 'lighter',
      fontSize: ['12px', '14px', 2, 5],
    },
    title: {
      fontFamily: 'subheading',
      fontWeight: 'body',
      fontSize: [3, null, 5, 6],
    },
    nav: {
      fontFamily: 'body',
      fontWeight: 'lighter',
      fontSize: [2, 3, 4],
      marginLeft: [2, 3],
      color: '#59666e',
    },
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'primary',
    },
  },
};
